(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/marketplace/components/retail-list-page/assets/javascripts/retail-list-onboarding.js') >= 0) return;  svs.modules.push('/modules/marketplace/components/retail-list-page/assets/javascripts/retail-list-onboarding.js');
'use strict';

const {
  Link,
  useLocation
} = ReactRouterDOM;
const {
  urlMapping
} = svs.core;
const {
  Button
} = svs.ui;
const {
  Hero
} = svs.components.marketplace.hero;
const {
  RetailOnboarding,
  GameSelector
} = svs.marketplace.components.common;
const {
  trackEvent
} = svs.components.analytics;
const {
  WidgetArea
} = svs.components.lbUtils.widgetArea;
const ListState = Object.freeze({
  NOT_LOGGED_IN: 'retail-list/not-logged-in',
  LOGGED_IN: 'retail-list/logged-in',
  NO_FOLLOW: 'retail-list/no-follow'
});
const getListState = (location, hasRetailers) => {
  const loggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
  if (!loggedIn) {
    return ListState.NOT_LOGGED_IN;
  }
  if (!hasRetailers) {
    return ListState.NO_FOLLOW;
  }
  return ListState.LOGGED_IN;
};
const getCopy = listState => {
  let message;
  switch (listState) {
    case ListState.LOGGED_IN:
      message = 'Här kan du söka och hitta andelsspel som passar dig!';
      break;
    case ListState.NO_FOLLOW:
      message = 'Har du ännu ingen favorit? Kolla här';
      break;
    default:
      return '';
  }
  return React.createElement("p", {
    className: "marketplace-hero-copy"
  }, message);
};
const getButton = listState => {
  switch (listState) {
    case ListState.LOGGED_IN:
      return React.createElement(Button, {
        className: "qa-find-team",
        inverted: true
      }, React.createElement(Link, {
        className: "qa-find-team-link",
        onClick: () => {
          trackEvent({
            category: 'Tillsammans',
            action: 'btn_interactions',
            opt_label: 'DINA OMBUD'
          });
        },
        to: "ombudslag"
      }, "Dina ombud"));
    case ListState.NO_FOLLOW:
      return React.createElement(Button, {
        className: "qa-find-team",
        inverted: true
      }, React.createElement(Link, {
        className: "qa-find-team-link",
        onClick: () => {
          trackEvent({
            category: 'Tillsammans',
            action: 'btn_interactions',
            opt_label: 'HITTA ANDELSSPEL'
          });
        },
        to: "hitta-andelsspel"
      }, "Hitta andelsspel"));
    default:
      return React.createElement("a", {
        className: "btn btn-300 btn-inverted btn-default qa-login-button",
        href: "/logga-in?returnUrl=".concat(urlMapping.get('marketplaceRetail'))
      }, React.createElement("span", {
        className: "btn-link-text"
      }, "Logga in"));
  }
};
const RetailListOnboarding = _ref => {
  var _svs$marketplace$data, _svs$marketplace$data2;
  let {
    hasRetailers
  } = _ref;
  const location = useLocation();
  const listState = getListState(location, hasRetailers);
  return React.createElement("div", {
    className: "bg-sport-50 marketplace-full-width padding-bottom-2",
    "data-testid": "qa-mkp-retail-welcome"
  }, React.createElement(Hero, {
    className: "retail-list-hero",
    horizontal: "center",
    image: (_svs$marketplace$data = svs.marketplace.data.heroData) === null || _svs$marketplace$data === void 0 ? void 0 : _svs$marketplace$data.retailHero.image,
    vertical: "middle"
  }, React.createElement("div", {
    className: "marketplace-hero-inner-content"
  }, React.createElement("h2", {
    className: "marketplace-hero-title"
  }, (_svs$marketplace$data2 = svs.marketplace.data.heroData) === null || _svs$marketplace$data2 === void 0 ? void 0 : _svs$marketplace$data2.retailHero.title), getCopy(listState), React.createElement("div", {
    className: "marketplace-hero-button-container"
  }, getButton(listState)))), React.createElement("div", {
    className: "marketplace-content-wrapper"
  }, React.createElement(RetailOnboarding, null), React.createElement(GameSelector, null), React.createElement(WidgetArea, {
    className: "marketplace-retail-widget-area",
    widgetArea: "retailWidgetArea"
  })));
};
setGlobal('svs.marketplace.components.retailListPage.RetailListOnboarding', RetailListOnboarding);

 })(window);